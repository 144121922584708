html,
body,
#root,
.App {
  width: 100%;
  height: 100%;
}

.map_overlay {
  height: 300px;
  width: 400px;
  position: absolute;
  bottom: 3.6em;
  right: 2em;
  z-index: 2;
  opacity: 0.6;
  transition: all 0.3s ease-in-out;
}
.map_overlay:hover {
  opacity: 1;
  height: 600px;
  width: 800px;
}
